import Navbar from "./NavbarItems";
import React from "react";
import {   } from "../Navbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const mobileNav = () => {
  document.querySelector(".sidebar").style.left = "0";
};

const App = () => {
  return (
    <header>
      <div className="restrict">
        <div className="nav-container">
          <div class="nav-item-mobile">
            <button onClick={mobileNav} className="hamburger">
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div className="nav-area">
            <Link to="/">
              <img
                alt=""
                src="https://user-images.githubusercontent.com/69129797/155841632-2c9a6c24-3463-4096-881f-b389fbb43575.png"
                width="200"
              />
            </Link>
            <div class="nav-item-desktop">
              <Navbar />
            </div>
          </div>
          <a href="https://register.conquest.org.in/"  rel="noreferrer">
            <button class="joinButton">REGISTER</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default App;
