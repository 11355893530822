// extracted by mini-css-extract-plugin
export var parentFooter = "Footer-module--parentFooter--b8IMm";
export var footerContainer = "Footer-module--footerContainer--JCbfW";
export var brandingContainer = "Footer-module--brandingContainer--GgKQ9";
export var logo = "Footer-module--logo--6kdQs";
export var inquiryForm = "Footer-module--inquiryForm--tI1hc";
export var inquiryInput = "Footer-module--inquiryInput--TS0FI";
export var inquiryButton = "Footer-module--inquiryButton--TJT62";
export var arrow = "Footer-module--arrow--yN4M3";
export var quickLinksContainer = "Footer-module--quickLinksContainer--HMPKK";
export var learnMoreContainer = "Footer-module--learnMoreContainer--tjhbG";
export var footerLink = "Footer-module--footerLink--q4ozN";
export var socialIconContainer = "Footer-module--socialIconContainer--yXNc9";
export var socialLink = "Footer-module--socialLink--awp7r";
export var credits = "Footer-module--credits--zE5ZY";
export var linksParent = "Footer-module--linksParent--jgT4U";