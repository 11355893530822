import React from "react";
import App from "./components/App";
import { Link } from "gatsby";

// styles
import "./App.css";
import "./custom.scss";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  // useProSidebar,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

export default function Navbar() {
  // const { collapseSidebar } = useProSidebar();
  return (
    <React.StrictMode>
      
      <div className="sidebar">
        <ProSidebar>
          <SidebarHeader>
            <Link to="/">
              <img
                alt=""
                src="https://user-images.githubusercontent.com/69129797/155062224-b3bc1445-e154-4f7d-a231-a06692559c80.png"
                width="200"
              />
            </Link>
          </SidebarHeader>
          <SidebarContent>
          {/* <button onClick={() => collapseSidebar()}>Collapse</button> */}
            <Menu iconShape="square">
              {/* <MenuItem>
                Home <Link to="/" />
              </MenuItem> */}

              <SubMenu title="Network">
                <MenuItem>Sponsors<Link to="/sponsors-and-partners" /></MenuItem>
                <MenuItem>Partners<Link to="/partners" /></MenuItem>
                <MenuItem>Mentors<Link to="/mentors" /></MenuItem>
                <MenuItem>Jury<Link to="/angels-jury" /></MenuItem>
              </SubMenu>

                          
              <MenuItem>
                Alumni <Link to="/alumni" />
              </MenuItem>

              <MenuItem>
                Media Presence <Link to="/news" />
              </MenuItem>

              <MenuItem>
                Newsletter <Link to="/newsletter" />
              </MenuItem>


              <SubMenu title="About Us">
                <MenuItem>Team<Link to="/about"/></MenuItem>
                <MenuItem>FAQs<Link to="/faqs" /></MenuItem>
              </SubMenu>


              <MenuItem>
                Work With Us <Link to="/work-with-us" />
              </MenuItem>
              
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <p>Copyright © Conquest 2023</p>
          </SidebarFooter>
        </ProSidebar>
      </div>
      <App />
    </React.StrictMode>
  );
}
