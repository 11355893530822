import React, { useEffect } from "react"; import {
  footerContainer,
  brandingContainer,
  quickLinksContainer,
  learnMoreContainer,
  contactContainer,
  inquiryForm,
  inquiryButton,
  arrow,
  inquiryInput,
  logo,
  footerLink,
  socialIconContainer,
  socialLink,
  credits,
  parentFooter,
  linksParent,
  brandCopy,
} from "./Footer.module.css";

import logoImg from "../../images/logo.png";
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLink } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { Link } from "gatsby";
import { Center } from "@chakra-ui/react";

export default function Footer() {

  useEffect(() => {
    const createCustomSubstackWidget = () => {
      window.CustomSubstackWidget = {
        substackUrl: "conquestbitspilani.substack.com",
        placeholder: "contact@conquest.org.in",
        buttonText: "Subscribe to our Newsletter",
        theme: "custom",
        colors: {
          primary: "#FB723D",
          input: "#FFFFFF",
          email: "#000000",
          text: "#FFFFFF",
        },
      };
      const script = document.createElement("script");
      script.src = "https://substackapi.com/widget.js";
      script.async = true;
      document.body.appendChild(script);
    };
    createCustomSubstackWidget();
  }, []);

  return (
    <div className={parentFooter}>

<Helmet>
  <script type="text/javascript">
    {`
    _linkedin_partner_id = "5525601";
    window._linkedin_data_partner_ids =
    window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `}
  </script>
  <script
    type="text/javascript"
    async
    src="https://snap.licdn.com/li.lms-analytics/insight.min.js"
  ></script>
  <noscript>
    {`
    <img
      height="1"
      width="1"
      style={{ display: "none" }}
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=5525601&fmt=gif"
    />
    `}
  </noscript>
</Helmet>

      <div className={footerContainer}>
        <div className={brandingContainer}>
          <img className={logo} src={logoImg} alt="" />
          <p className={brandCopy}>
            We are India’s largest student-led startup accelerator entirely run
            by Center for Entrepreneurial Leadership, BITS Pilani.
          </p>
        </div>
        <div className={linksParent}>
          <div className={learnMoreContainer}>
            <h2>Quick Links</h2>
            <Link className={footerLink} to="/alumni">
              <p>Alumni</p>
            </Link>
            <Link className={footerLink} to="/sponsors">
              <p>Sponsors</p>
            </Link>
            <Link className={footerLink} to="/partners">
              <p>Resource Partners</p>
            </Link>
            <Link className={footerLink} to="/mentors">
              <p>Mentors</p>
            </Link>
          </div>
          <div className={quickLinksContainer}>
            <h2>Learn More</h2>

            <Link className={footerLink} to="/about">
              <p>About Us</p>
            </Link>
            <Link className={footerLink} to="/process">
              <p>Our Process</p>
            </Link>
            <a className={footerLink} to="/pre-reg">
              <p>Register</p>
            </a>
            <Link className={footerLink} to="/privacy">
              <p>Privacy Policy</p>
            </Link>
          </div>
        </div>

        <div className={contactContainer}>
          <h2>Contact Us</h2>
          <p>
            +91 99716 96217
            <br />
            contact@conquest.org.in
          </p>
          <div className={socialIconContainer}>
            <Link
              className={socialLink}
              href="https://twitter.com/ConquestBITS"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
            <Link
              className={socialLink}
              href="https://www.linkedin.com/company/conquest---bits/"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
            <Link
              className={socialLink}
              href="https://www.facebook.com/ConquestBITS"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link
              className={socialLink}
              href="https://www.instagram.com/ConquestBITS"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link
              className={socialLink}
              href="https://www.youtube.com/channel/UCt4foktbytTA9-9ZgPfGFOA"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
          </div>
          <br></br>
          <Center><div id="custom-substack-embed"></div></Center>
        </div>
      </div>

      <hr
        style={{
          color: "#E1E1E1",
        }}
      />
      <p className={credits}>Copyright © Conquest 2023</p>
    </div>
  );
}
