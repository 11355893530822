export const menuItems = [
  // {
  //   title: "Home",
  //   link: "/",
  // },
  // {
  //   title: "Our Process",
  //   link: "/process",
  // },
  
  {
    title: "Network",
    submenu: [
      {
        title: "Sponsors",
        link: "/sponsors-and-partners",
      },
      {
        title: "Partners",
        link: "/partners",
      },
      {
        title: "Jury",
        link: "/angels-jury",
      },
      {
        title: "Mentors",
        link: "/mentors",
      },

    ],
  },
  { 
        title: "Alumni",
        link: "/alumni",
  },
  {
    title: "Media Presence",
    link: "/news",
  },

  // {
  //   title: "Web3 Track",
  //   link: "/web3",
  // },
  {
    title: "Newsletter",
    link: "/newsletter",
  },

  {
    title: "About Us",
    submenu: [
      {
        title: "Team",
        link: "/about",
      },
      // {
      //   title: "Work With Us",
      //   link: "/work-with-us",
      // },
      {
        title: "FAQs",
        link: "/faqs",
      },

    ],
  },

  {
    title: "Work With Us",
    link: "/work-with-us",
  },

  // {
  //   title: "Team",
  //   link: "/about",
  // },
  
];
